<template>
    <div v-loading="$wait.is('loading')" class="card card-f-h" element-loading-spinner="el-custom-spinner">
        <div class="flex justify-end mb-2 border-b pb-4">
            <el-button type="primary" :loading="$wait.is('updating')" @click="markAllAsSeen">
                {{ $t('notifications.mark_all_as_read') }}
            </el-button>
        </div>
        <el-table v-if="notificationsData.length > 0" :data="notificationsData" style="width: 100%" :show-header="false">
            <el-table-column width="60">
                <template slot-scope="scope">
                    <fa-icon v-if="scope.row.data.icon == 'info'" :icon="['fas', 'info-circle']" class="text-4xl mt-1 text-blue-200 " />
                    <fa-icon v-if="scope.row.data.icon == 'success'" :icon="['fas', 'check-circle']" class="text-4xl mt-1 text-green-200 " />
                    <fa-icon v-if="scope.row.data.icon == 'warning'" :icon="['fas', 'exclamation-circle']" class="text-4xl mt-1 text-yellow-200" />
                </template>
            </el-table-column>

            <el-table-column>
                <template slot-scope="scope">
                    <p class="font-semibold">
                        {{ scope.row.data.title }}
                    </p>
                    <p>{{ scope.row.data.content }}</p>
                </template>
            </el-table-column>

            <el-table-column prop="created_at" width="120" />

            <el-table-column width="50">
                <template slot-scope="scope">
                    <p v-if="!scope.row.read_at" class="cursor-pointer text-green-200 hover:text-green-400 transition">
                        <fa-icon :icon="['fas', 'check-circle']" class="text-3xl mt-1" @click="markAsSeen(scope.row.id)" />
                    </p>
                </template>
            </el-table-column>
        </el-table>

        <div v-if="notificationsData.length > 0" class="flex justify-center mt-6 font-thin w-full">
            <el-pagination
                class="font-bold"
                small
                layout="prev, pager, next, ->, sizes"
                :total="parseInt(query.totalPages)"
                :page-size="parseInt(query.pageSize)"
                :current-page="parseInt(query.currentPage)"
                :page-sizes="[20, 50, 100, 200]"
                @current-change="pageChanged"
                @size-change="sizeChanged"
            />
        </div>

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    props: {
        query: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            noDataInformation: false,
            notificationsData: [],
        };
    },

    computed: {
        userUuid() {
            return this.$store.state.user.user.user_uuid;
        },
    },

    watch: {
        userUuid() {
            if (!this.userUuid) return;
            this.initPusher();
        },
    },

    created() {
        this.initPusher();
        this.getAll();
        this.$store.commit('setPageTitle', this.$t('notifications.notificatios'));
        this.$store.commit('setActiveMenuItem', '20');
    },

    methods: {
        initPusher() {
            if (!this.userUuid) return;
            window.Echo.private(`App.Models.User.${this.userUuid}`)
                .notification(() => {
                    this.getAll();
                });
        },

        async getAll() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/notifications`, {
                params: {
                    pageSize: parseInt(this.query.pageSize, 10) || 20,
                    page:     parseInt(this.query.currentPage, 10) || 1,
                },
            });
            this.notificationsData = data.notifications;
            this.noDataInformation = data.notifications.length;

            this.updateUrl({ totalPages: data.total_number });

            this.$wait.end('loading');
        },

        async markAsSeen(uuid) {
            this.$wait.start('loading');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/notifications/mark_as_read/${uuid}`);
            this.$store.commit('user/removeNotificationFromUnseen', uuid);
            this.$notify.success({ title: 'Success' });
            this.getAll();
        },

        async markAllAsSeen() {
            this.$wait.start('updating');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/notifications/mark_all_as_read`);
            this.$store.commit('user/removeAllNotifications');
            this.$notify.success({ title: 'Success' });
            this.$wait.end('updating');
            this.getAll();
        },

        async pageChanged(currentPage) {
            await this.updateUrl({ currentPage });
            this.getAll();
        },

        async sizeChanged(pageSize) {
            await this.updateUrl({ pageSize });
            this.getAll();
        },

        async updateUrl({ totalPages = this.query.totalPages, pageSize = this.query.pageSize, currentPage = this.query.currentPage }) {
            const query = { ...this.query };
            query.totalPages = totalPages;
            query.pageSize = pageSize;
            query.currentPage = currentPage;
            await this.$router.replace({ query }).catch(() => {});
        },
    },
};
</script>
<style>
    .el-pager {
        font-weight: 600;
    }
</style>
